import { OwlOptions } from 'ngx-owl-carousel-o';

/**
 * @description owl carousel configuration
 * @since 2020-Nov-11
 */
export const OWL_OPTIONS: OwlOptions =  {
    autoplay: true,
    loop: true,
    // mouseDrag: false,
    // pullDrag: true,
    // touchDrag: true,
    margin: 10,
    dots: true,
    navSpeed: 400,
    // navText: ['',''],
    responsive: {
        0: {
            items: 1,
            nav: true,
            margin: 10
        },
        667: {
            items: 1,
            nav: true,
            margin: 10
        },
        768: {
            items: 1,
            nav: true,
            margin: 10
        },
        992: {
            items: 1,
            nav: true,
            loop: false,
            margin: 10
        },
        1280: {
            items: 1,
            nav: true,
            margin: 10
        }
    }
};